import DocumentsInvoicesService from "@/services/DocumentsInvoicesService";

beforeEach(() => {
  jest.restoreAllMocks();
});

describe("DocumentsInvoicesService", () => {
  describe("mapIncomeAndExpenseSummaries", () => {
    it("should return empty array if empty array is passed", () => {
      // Given
      // When
      const result = DocumentsInvoicesService.mapIncomeAndExpenseSummaries([]);
      // Then
      expect(result).toStrictEqual([]);
    });
    it("should return last day (December 31) of last year as date", () => {
      // Given
      // When
      const result = DocumentsInvoicesService.mapIncomeAndExpenseSummaries([
        {
          files: [],
          uploadDate: "2025-01-01T00:00:00.000Z",
          date: "2025-01-01T00:00:00.000Z",
        },
      ]);
      // Then
      expect(result).toStrictEqual([
        {
          files: [],
          uploadDate: "2025-01-01T00:00:00.000Z",
          date: "2024-12-31T00:00:00.000Z",
        },
      ]);
    });
  });
});
